{register} = require 'src/js/infrastructure/setup-registry'
{getModal} = require 'src/coffee/controls/modals'

setup = ($ctx) ->
    console.log 'UploadNewImageSetup'
    $ctx.find('#new-image').on 'click', (event) ->
        event.preventDefault()
        $button = $(event.currentTarget)
        getModal $button.data 'modal-url'

register 'App.UAV.ImageManagement.UploadNewImage.Setup', setup