{register} = require 'src/js/infrastructure/setup-registry'
{antiForgeryPost} = require 'src/coffee/infrastructure/ajax'

{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'
{makeRefreshHandler} = require 'src/coffee/helpers/async/fragment-loading'


setup = ($ctx) ->
    $btn = $ctx.find('#confirm-image-deletion-button')
    $modal = $ctx.find('#delete-image-modal')
    $btn.on 'click', (event) ->

        url = $btn.data 'url'
        data =
            entityId: $btn.data 'entity-id'
            imageId: $btn.data 'image-id'

        # hide the modal directly or the dropshadow will stick around, no idea why, probably a
        $modal.modal('hide')

        antiForgeryPost url, data
        .then makeRefreshHandler()
        .then makeSuccessHandler $btn
        .fail makeErrorHandler $btn


register 'App.UAV.ImageManagement.DeleteImageModal.setup', setup
