{register} = require 'src/js/infrastructure/setup-registry'

{waitscreen} = require 'src/js/infrastructure'
{register} = require 'src/js/infrastructure/setup-registry'
{antiForgeryPost} = require 'src/coffee/infrastructure/ajax'

{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'
{makeRefreshHandler} = require 'src/coffee/helpers/async/fragment-loading'


_cameraEditorTemplate = null

setup = ($ctx) ->
    $ctx.find('.buttons-create').off 'click'
    $ctx.find('.buttons-edit').off 'click'

    $ctx.find('.buttons-create').on 'click', (e) -> openEditorModal(e, 'create')
    $ctx.find('.buttons-edit').on 'click', (e) -> openEditorModal(e, 'edit')

    $ctx.find('#save-button').on 'click', saveChanges

    # remove the editor from the dom or validate will attach an error messages after each name-textbox click (detaching won't work)
    _cameraEditorTemplate = $ctx.find('.camera-editor-modal').remove()


openEditorModal = (event, mode) ->
    event.preventDefault(true)

    $editor = _cameraEditorTemplate.clone()

    $editor.find('.add-channel-button').on 'click', -> addChannelRow($editor)
    $editor.find('.remove-channel-button').on 'click', -> removeChannelRow($(this).closest('tr.channel-row'))

    $editor.find('.confirm-changes-button').on 'click', (innerEvent) ->
        if validateChanges($editor)
            updateGrid($editor)
        else
            innerEvent.preventDefault(true)

    $editor.find('.camera-editor-form').validate()

    # attach the mode to the modal DOM node
    $editor[0].mode = mode

    # shortcut
    return if mode is 'create'
        $editor[0].selectedRow = null
        $editor.modal('show')

    # attach the selectedRow to the root DOM node
    selectedRow = $editor[0].selectedRow = $('#camera-grid').staticgrid()[0].table.row('.selected').data()

    $editor.find('.name-textbox').val selectedRow.name
    $editor.find('.description-textbox').val selectedRow.description

    # this might change, should probably just add a parsed version to the grid when it's loaded
    channels = selectedRow.channels

    # fill the channels
    for channel in channels
        addChannelRow($editor, channel)

    $editor.modal('show')


addChannelRow = ($editor, selectedChannel) ->
    $container = $editor.find('.channel-rows-container')

    # get the template and clone it with events
    $row = $editor.find('.channel-row.hidden').clone(true)

    if selectedChannel
        $channelTypeOptions = $row.find('.channel-types option')

        # set the selected channel option
        for option in $channelTypeOptions
            $option = $(option)
            $option.attr('selected', $option.val() is selectedChannel)

    $row.removeClass('hidden')
    $container.append($row)

    assignChannelNumbers($editor)
    validateChannels($editor)


removeChannelRow = ($row) ->
    $editor = $row.closest('.camera-editor-modal')
    $row.remove()
    assignChannelNumbers($editor)
    validateChannels($editor)


assignChannelNumbers = ($editor) ->
    i = 0
    for channelNumber in $editor.find('.channels-table .channel-rows-container .channel-number')
        $(channelNumber).text(++i)


validateChannels = ($editor) ->
    hasChannels = $editor.find('.channel-rows-container .channel-types option:selected').length > 0
    $validationRow = $editor.find('.channels-validation-message-row')

    if hasChannels
        $validationRow.hide()
    else
        $validationRow.show()

    return hasChannels


updateGrid = ($editor) ->
    mode = $editor[0].mode
    row = $editor[0].selectedRow
    if mode is 'create'
        row = {}

    $options = $editor.find('.channel-rows-container .channel-types option:selected')

    stringBuilder = ''
    channels = []

    for option in $options
        $option = $(option)
        channels.push $option.val()
        stringBuilder += $option.text() + ', '

    if stringBuilder.length > 0
        stringBuilder = stringBuilder.substring(0, stringBuilder.length - 2)

    row.name = $editor.find('.name-textbox').val()
    row.description = $editor.find('.description-textbox').val()
    row.channels = channels
    row.channelsString = stringBuilder

    tracker = $('#camera-grid').staticgrid()[0].state

    if mode is 'edit'
        tracker.editRow(row)
    else
        tracker.addRow(row)

    $editor.modal('hide')
    $editor.detach()


validateChanges = ($editor) ->
    nameValid = $editor.find('.camera-editor-form').valid()
    hasChannels = validateChannels($editor)

    return nameValid and hasChannels


saveChanges = ->
    $btn = $(this)
    grid = $('#camera-grid').staticgrid()[0]
    tracker = grid.state

    return unless tracker.hasChanges()

    url = $btn.data 'url'
    data =
        states: tracker.changes()

    promise =
        antiForgeryPost url, data
        .done makeRefreshHandler()
        .then makeSuccessHandler $btn
        .fail makeErrorHandler $btn

    waitscreen.waitFor promise


register 'App.UAV.CameraManagement.setup', setup
