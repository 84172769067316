{waitscreen} = require 'src/js/infrastructure'
{register} = require 'src/js/infrastructure/setup-registry'
{antiForgeryPost} = require 'src/coffee/infrastructure/ajax'

{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'
{makeReplaceHandler} = require 'src/coffee/helpers/async/fragment-loading'

{getByName} = require 'src/coffee/controls/map'

WKT = require('ol/format/WKT').default
Feature = require('ol/Feature').default
Style = require('ol/style/Style').default
Stroke = require('ol/style/Stroke').default
Fill = require('ol/style/Fill').default
VectorLayer = require('ol/layer/Vector').default
VectorSource = require('ol/source/Vector').default

setup = ($ctx, promise) ->
    $refreshButton = $ctx.find('#refresh-preview-button')
    $refreshButton.on 'click', -> refreshPreview $refreshButton

    $ctx.find('#save-zonemap-button')
    .on 'click', saveZoneMap

    promise
    .then ->
        geometries = {}
        for zone in $('#data-container .zone-info')
            $zone = $ zone
            geometries[$zone.data('value')] = $zone.data('geometry')
        updateZoneMap geometries
    .then -> refreshPreview($refreshButton)

getSliverRemovalParameters = ->
    #relative: $('#relative').val()
    maxSliverSize: parseFloat $('#max-sliver-size').val()
    maxSliverSizeElongated: parseFloat $('#max-sliver-size-elongated').val()
    maxSquareRatio: parseFloat $('#max-square-ratio').val()
    #areaMultiplier: parseFloat $('#area-multiplier').val()
    #allowPointIntersection: $('#allow-point-intersection').val()


getPostProcessingParameters = ->
    $container = $('#data-container')
    entityId: $container.data 'entity-id'
    shapeFile: $container.data 'shape-file'
    sliverRemovalParameters: getSliverRemovalParameters()


getSavingParameters = ->
    $container = $('#data-container')
    entityId: $container.data 'entity-id'
    entityName: $container.data 'entity-name'
    zoneMapName: $container.data 'zonemap-name'
    imageId: $container.data 'image-id'
    shapeFile: $container.data 'shape-file'
    sliverRemovalParameters: getSliverRemovalParameters()


updateZoneMap = (processedGeometries) ->
    mapControl = getByName 'ZoneMap'

    layerName = $('#data-container').data 'zonemap-layername'
    currentLayer = $('.mapcontrol').data 'current-layer'

    if currentLayer
        mapControl.removeLayerByName currentLayer

    $zones = $('#data-container .zone-info')

    wktConverter = new WKT
    features = []

    for i, wkt of processedGeometries

        $zone = $zones.filter("[data-value=#{i}]")

        color = $zone.data 'color'
        fillColor = color[..]
        fillColor[3] = 0.4

        style = new Style
            fill: new Fill
                color: $zone.data 'color'
            stroke: new Stroke
                color: $zone.data 'color'
                width: 1

        feature = new Feature
            geometry: wktConverter.readGeometry wkt,
                dataProjection: 'EPSG:4326',
                featureProjection: 'EPSG:900913'

        feature.setStyle style
        feature.setProperties
            name: $zone.data 'name'
            uid: $zone.data 'name'

        feature.setId $zone.data 'name'

        features.push feature

    layer = new VectorLayer
        source: new VectorSource
            features: features

    layer.set 'name', layerName
    layer.set 'uid', '11111111-1111-1111-1111-111111111111'
    layer.set 'id', 'vector/#11111111-1111-1111-1111-111111111111'
    layer.set 'geometryType', 'vector'
    layer.set 'canAdd', false
    layer.set 'editable', false
    layer.set 'interactive', false
    layer.set 'numberOfAllowedFeatures', features.length

    mapControl.layerManager.groups['vector'].getLayers().insertAt 0, layer

    $('.mapcontrol').data 'current-layer', layerName


refreshPreview = ($btn) ->
    url = $btn.data 'url'
    data = getPostProcessingParameters()

    # todo: display a progress bar
    promise = antiForgeryPost(url, data)
    .done (processedGeometries) -> updateZoneMap processedGeometries
    .then makeSuccessHandler $btn
    .fail makeErrorHandler $btn

    waitscreen.waitFor promise


saveZoneMap = ->
    $btn = $(this)

    url = $btn.data 'url'
    data = getSavingParameters()

    promise = antiForgeryPost(url, data)
    .done makeReplaceHandler $('#content')
    .then makeSuccessHandler $btn
    .fail makeErrorHandler $btn

    waitscreen.waitFor promise


register 'App.UAV.ZoneMaps.PostProcessing.setup', setup
