{waitscreen} = require 'src/js/infrastructure'
{register} = require 'src/js/infrastructure/setup-registry'
{antiForgeryPost} = require 'src/coffee/infrastructure/ajax'

{makeErrorHandler} = require 'src/coffee/helpers/async/notifications'
{makeRefreshHandler} = require 'src/coffee/helpers/async/fragment-loading'

{getByName} = require 'src/coffee/controls/map'

{LayerSwitcherPlugin} = require 'src/coffee/controls/map/plugins/layer-switcher'
{createZonemapColormappingOperation, getNDVIBins, quantize} = require 'src/coffee/controls/map/utilities/raster'

Style = require('ol/style/Style').default
Fill = require('ol/style/Fill').default
Vector = require('ol/source/Vector').default
Raster = require('ol/source/Raster').default

{Chart} = require 'chart.js'

parseNumber = null
formatNumber = null


setup = ($ctx, promise) ->
    parseNumber = Globalize.numberParser()
    formatNumber = Globalize.numberFormatter()

    validateMandatoryFields $ctx
    changeImageLayer $ctx

    $images = $ctx.find('#images')

    return unless Object.keys($images.selectize()[0].selectize.options).length > 0

    $images.on 'change', -> changeImageLayer()

    $ctx.find('#zonemap-name-textbox')
    .prop 'readonly', false
    .on 'blur', -> validateMandatoryFields()

    $form = $ctx.find('#create-applicationmap-form')
    .on 'submit', (event) ->
        event.preventDefault()
        createApplicationMap(event) if $form.valid()
        return false

    promise.then createHistogram


createHistogram = ->
    bins = 40
    canvas = $('#histogram').get(0)
    dataset =
        label: 'NDVI'
        data: [0..bins - 1].map((x) -> 0)
        backgroundColor: 'green'
        hoverBackgroundColor: '#DDDDDD'
        borderWidth: 0
    chartOptions =
        type: 'bar'
        data:
            labels: [0..bins - 1].map((x) -> (2 * x / bins - 1).toFixed(2))
            datasets: [dataset]
        options:
            responsive: true
            scales:
                yAxes: [{display: false}]
            legend:
                display: false
    chart = new Chart(canvas, chartOptions)
    canvas.chart = chart


changeImageLayer = ($ctx = $('body')) ->
    $images = $ctx.find('#images')

    selectize = $images.selectize()[0].selectize
    option = selectize.options[selectize.items[0]]
    mapcontrol = getByName 'ImageMap'

    currentLayerName = $ctx.find('.mapcontrol').data 'current-layer-name'

    # cleanup
    if currentLayerName
        mapcontrol.removeLayerByName currentLayerName
        mapcontrol.removeLayerByName option.ndvilayername # TODO rename to ndviLayerName
        mapcontrol.removeLayerByName option.cirlayername # TODO rename to cirLayerName

    if $images.val()
        # add layers
        mapcontrol.addMapServerLayer option.filteredndvilayer, option.borderlayername # TODO find out why this is the image name in C# and rename to imageName
        mapcontrol.addMapServerLayer option.ndvilayer, option.ndvilayername # TODO rename to ndviLayer, ndviLayerName
        mapcontrol.addMapServerLayer option.cirlayer, option.cirlayername # TODO rename to cirLayer, cirLayerName

        borderLayer = mapcontrol.layerManager.layers.vector()[0]
        maskSource = new Vector
            source: borderLayer.getSource()
            style: new Style
                fill: new Fill
                    color: 'white'
            renderMode: 'image'

        # zoning layer
        zoningLayer = mapcontrol.layerManager.layers.data()[0]
        rasterSource = new Raster
            sources: [zoningLayer.getSource(), maskSource]
        zoningLayer.setSource(rasterSource)
        addColormappingOperation(rasterSource)

        mapcontrol.map.render()

        for plugin in mapcontrol.plugins
            if plugin instanceof LayerSwitcherPlugin
                plugin.updateContent()

        $ctx.find('.mapcontrol').data 'current-layer-name', option.borderlayername # TODO change to imageName


getNDVIBins = ->
    bins = document.querySelector('#zone-ranges').rangePicker.getValues()
    return bins


addColormappingOperation = (rasterSource) ->
    $rangePicker = $('#zone-ranges')
    rangePicker = $rangePicker.get(0).rangePicker
    window.rangePicker = rangePicker

    histogram = $('#histogram').get(0)
    dataset = histogram.chart.data.datasets[0]

    setHistogramColors = (bins, zoneColors) ->
        if not bins.length
            dataset.backgroundColor = 'black'
            return

        dataset.backgroundColor = for i in [0..dataset.data.length - 1]
            normalized = (i + 0.5) / dataset.data.length
            index = quantize(2 * (normalized - 0.5), bins)
            color = zoneColors[index]
            "rgba(#{color[0]}, #{color[1]}, #{color[2]}, 1)"

    skipNextHistogramUpdate = false
    updateHistogram = (newData) ->
        if skipNextHistogramUpdate
            skipNextHistogramUpdate = false
            return
        dataset.data = newData.filter (x) -> not isNaN(x)
        histogram.chart.update()

    createHistogramHoverHandler = (data) -> (elements) ->
        previous = data.highlight[0]

        if elements.length
            center = elements[0]._index / dataset.data.length
            data.highlight[0] = center
            data.highlight[1] = center + 1 / dataset.data.length
        else
            data.highlight[0] = -1
            data.highlight[1] = -1

        if data.highlight[0] isnt previous
            skipNextHistogramUpdate = true
            rasterSource.changed()

        return true

    updateOperation = ->
        bins = getNDVIBins()

        {operation, lib, data} = createZonemapColormappingOperation(bins, new Array(dataset.data.length))

        resetHistogram = ->
            for x, i in data.histogram
                data.histogram[i] = 0

        rasterSource.setOperation(operation, lib)

        setHistogramColors(bins, data.zoneColors)
        rangePicker.options.colorFunction = (i) ->
            if not data.zoneColors[i]
                return 'transparent'
            [r, g, b] = data.zoneColors[i]
            return "rgb(#{r}, #{g}, #{b})"

        rangePicker.redraw(true)

        rasterSource.un 'beforeoperations'
        rasterSource.on 'beforeoperations', (event) ->
            resetHistogram()
            Object.assign(event.data, data)

        rasterSource.un 'afteroperations'
        rasterSource.on 'afteroperations', (event) ->
            updateHistogram(event.data.histogram)

        histogram.chart.options.hover.onHover = createHistogramHoverHandler(data)

    $rangePicker.off('change')
    $rangePicker.on('change', updateOperation)
    # $('#zones-table').off('change')
    # $('#zones-table').on('change', updateOperation)

    updateOperation(rasterSource)


validateMandatoryFields = ($ctx = $('body')) ->
    $submitButton = $ctx.find('#create-applicationmap-button')
    $form = $ctx.find('#create-applicationmap-form')

    if $form.valid()
        $submitButton.enable()
    else
        # todo: add a popover
        $submitButton.disable()


createApplicationMap = (event) ->
    $form = $(event.target)

    imageId = $('#images').val()

    url = $form.prop 'action'
    data =
        entityId: $form.data 'entity-id'
        entityName: $form.data 'entity-name'
        imageId: imageId
        zoneMapName: $('#zonemap-name-textbox').val()
        zoneValues: getNDVIBins()

    promise =
        antiForgeryPost(url, data)
        .done makeRefreshHandler()
        .fail makeErrorHandler $form

    waitscreen.waitFor promise


register 'App.UAV.ZoneMaps.Create.setup', setup
