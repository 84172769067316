{waitscreen} = require 'src/js/infrastructure'
{register} = require 'src/js/infrastructure/setup-registry'
{post, postJson, antiForgeryPost} = require 'src/coffee/infrastructure/ajax'

{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'
{makeRefreshHandler} = require 'src/coffee/helpers/async/fragment-loading'

{getModal} = require 'src/coffee/controls/modals'
{getByName} = require 'src/coffee/controls/map'

setup = ($ctx, promise) ->
    $zoneMaps = $ctx.find '#zonemaps'
    $ctx.find('#activate-button').on 'click', updateOrDeleteZoneMap
    $ctx.find('#deactivate-button').on 'click', updateOrDeleteZoneMap
    $deleteButton = $ctx.find('#delete-button')
    entityId = $ctx.find('#EntityId').val()
    entityName = $ctx.find('#EntityName').val()
    $deleteButton.on 'click', ->
        modalId = $deleteButton.data 'modal'
        $modal = $ "##{modalId}"
        $modal.modal 'show'

        $cancelButton = $modal.find 'button[type="button"]'
        $submitButton = $modal.find 'button[type="submit"]'

        $cancelButton.on 'click', ->
            $modal.modal 'hide'

        $submitButton.on 'click', ->
            url = $deleteButton.data 'delete-url'
            data =
                zoneMapId: $zoneMaps.val()
                entityId: entityId
                entityName: entityName

            return post url, data
            .then makeRefreshHandler()
            .then makeSuccessHandler $deleteButton
            .then ->
                selectedZoneMap = getSelectedZoneMap()
                updateZoneMapState selectedZoneMap, selectedZoneMap?.state
                updateMapControl selectedZoneMap
            .fail makeErrorHandler $deleteButton
            .always $modal.modal 'hide'

    $zoneMaps.on 'change', ->
        selectedZoneMap = getSelectedZoneMap()

        updateZoneMapState selectedZoneMap, selectedZoneMap?.state
        updateMapControl selectedZoneMap

    $downloadButton = $ctx.find '#download-button'
    $downloadButton.on 'click', ->
        url = $downloadButton.data 'modal-url'

        zoneMapId = $zoneMaps.val()
        entityId = $('#EntityId').val()

        geodata = {}
        geodata[entityId] = {}
        geodata[entityId][zoneMapId] = $zoneMaps.text()

        getModal url, geodata, postJson
        .then ($modal) -> $modal.data 'legacy-geodata', geodata

    promise.done ->
        zoneMap = getSelectedZoneMap()
        updateZoneMapState zoneMap, zoneMap?.state
        updateMapControl zoneMap


updateMapControl = (selectedZoneMap) ->
    layer = selectedZoneMap?.layer
    layerName = selectedZoneMap?.layername

    map = getByName 'zoneMap'
    currentLayerName = $('.mapcontrol').data 'layerName'

    if currentLayerName and currentLayerName isnt layerName
        map.removeLayerByName currentLayerName

    if layer
        map.clearMapServerLayer()
        map.addMapServerLayer layer, layerName
        map.map.render()


getSelectedZoneMap = ->

    # can't get any longer now, can it?? I'd prefer $ctx.find('#zonemaps option:selected')
    selectize = $('#zonemaps')[0].selectize
    selectedZoneMap = null

    if selectize.items.length > 0
        selectedZoneMap = selectize.options[selectize.items[0]]

    return selectedZoneMap


updateOrDeleteZoneMap = ->
    $btn = $(this)

    selectedZoneMap = getSelectedZoneMap()
    url = $btn.data 'url'
    data =
        ZoneMapId: selectedZoneMap.value

    promise =
        antiForgeryPost url, data
        .done (zoneMapState) ->
            updateZoneMapState(selectedZoneMap, zoneMapState)
        .then makeSuccessHandler $btn
        .fail makeErrorHandler $btn

    waitscreen.waitFor promise


updateZoneMapState = (zoneMap, zoneMapState) ->
    $activateButton = $ '#activate-button'
    $deactivateButton = $ '#deactivate-button'
    $deleteButton = $ '#delete-button'
    $downloadButton = $ '#download-button'
    $applicationMapButton = $ '#create-applicationmap-button'

    # quad-state handling, todo: add popovers for disabled buttons
    if zoneMapState is 'Deleted' or not zoneMap
        $deactivateButton.disable().hide()
        $activateButton.disable().show()
        $deleteButton.disable()
        $downloadButton.disable()
        $applicationMapButton.disable()

        # only remove the item if there is one
        if zoneMap
            selectize = $('#zonemaps')[0].selectize

            # only clear when the same map is currently selected (concurrency)
            if selectize.getValue() is zoneMap.value
                selectize.clear()

            selectize.removeOption(zoneMap.value)

    else if zoneMap
        if zoneMapState is 'Activated'
            $activateButton.hide()
            $deactivateButton.enable().show()
        else
            $deactivateButton.hide()
            $activateButton.enable().show()

        zoneMap.state = zoneMapState
        $deleteButton.enable()
        $downloadButton.enable()
        $applicationMapButton.enable()


register 'App.UAV.ZoneMaps.View.setup', setup
