{waitscreen} = require 'src/js/infrastructure'
{register} = require 'src/js/infrastructure/setup-registry'
{get, antiForgeryPost} = require 'src/coffee/infrastructure/ajax'

{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'
{makeRefreshHandler} = require 'src/coffee/helpers/async/fragment-loading'

{getByName} = require 'src/coffee/controls/map'
{defineStyle, getStyle} = require 'src/coffee/controls/map/utilities/styling'

{local} = require 'src/coffee/controls/map/servers'
WKT = require('ol/format/WKT').default

defineStyle 'uav.analysis.comparison',
    fill: 'rgba(245, 215, 0, 0.1)'
    stroke: 'rgba(245, 215, 0, 0.3)'
    label: true


formatArea = (area) ->
    area = Math.round(area*100)/100
    if area >= 10000
        return Math.round(area / 10000 * 1000) / 1000 + ' ha'
    else
        return Math.round(area * 1000) / 1000 + ' m²'


formatLength = (length) ->
    length = Math.round(length*100)/100
    if length >= 100
        return Math.round(length / 1000 * 100) / 100 + ' km'
    else
        return Math.round(length * 100) / 100 + ' m'


syncMapViews = (maps) ->
    for map in maps
        do (map) ->
            view = map.getView()
            others = maps.filter((x) -> x isnt map).map((x) -> x.getView())

            view.on 'change:center', (event) ->
                for other in others
                    other.setCenter(view.getCenter())
            view.on 'change:resolution', (event) ->
                for other in others
                    other.setResolution(view.getResolution())
            view.on 'change:rotation', (event) ->
                for other in others
                    other.setRotation(view.getRotation())

calculateMeasurements = (feature, mapcontrol) ->
    switch feature.getGeometry().getType()
        when 'Polygon'
            area = mapcontrol.getFeatureArea feature
            feature.set 'area', area
            text = feature.get 'text'
            feature.set 'label', "#{text or ''} (#{formatArea area})"
        when 'LineString'
            length = mapcontrol.getFeatureLength feature
            feature.set 'length', length
            text = feature.get 'text'
            feature.set 'label', "#{text or ''} (#{formatLength length})"

syncVectorLayers = ($container) ->
    combined = $container.find('#combined-view').mapcontrol()[0]
    sbsLeft = $container.find('#sbs-view-left').mapcontrol()[0]
    sbsRight = $container.find('#sbs-view-right').mapcontrol()[0]

    combinedWebgisLayers = combined.layerManager.layers?.webgis()

    for layer in combinedWebgisLayers
        for feature in layer.getSource().getFeatures()
            calculateMeasurements feature, combined

    leftWebgisLayers = sbsLeft.layerManager.layers.webgis()

    for layer, i in leftWebgisLayers
        correspondingLayer = combinedWebgisLayers[i]

        layer.setSource correspondingLayer.getSource()

    idProvider = combined.webgis.plugins.find((x) -> x.geometryType).idProvider

    for plugin, i in combined.webgis.plugins
        continue if not plugin.geometryType

        plugin.setIdProvider idProvider

    for plugin, i in sbsLeft.webgis.plugins
        continue if not plugin.geometryType

        plugin.setIdProvider idProvider

        plugin.interaction.on 'drawend', (evt) -> calculateMeasurements evt.feature, combined
        plugin.map.removeInteraction plugin.interaction
        plugin.source.un 'addfeature', plugin._checkMaximumNumberOfFeatures
        plugin.source = plugin.layer.getSource()
        plugin.source.on 'addfeature', plugin._checkMaximumNumberOfFeatures
        plugin._initializeInteraction()

    for plugin, i in combined.webgis.plugins
        continue if not plugin.geometryType

        plugin.interaction.on 'drawend', (evt) -> calculateMeasurements evt.feature, combined

    sbsRight.layerManager.groups.vector.setLayers(combined.layerManager.groups.vector.getLayers())

    entityId = $('#EntityId').val()
    for layer in combined.layerManager.layers.vector().filter((x) -> x.get('uid') isnt entityId)
        layer.setStyle getStyle 'uav.analysis.comparison'

copySelection = ($from, $to) ->
    getSelects = ($view) ->
        findMapControls($view)
        .filter((x) -> x.webgis)
        .map((x) -> x.webgis.interactions.select)

    from = getSelects($from)[0].getFeatures()
    to = getSelects($to)[0].getFeatures()

    to.clear()
    for feature in from.getArray()
        to.push feature

findMapControls = ($element) -> $element.find('.mapcontrol').mapcontrol()
findMaps = ($element) -> $element.find('.mapcontrol').mapcontrol().map((x) -> x.map)

activeMapcontrol = null
selectionChangeListener = null

setupFeatureTextEditing = ($view) ->
    $textarea = $('#annotation')

    onSelectionChange = (event) ->
        features = activeMapcontrol.webgis.interactions.select.getFeatures()
        if features.getLength() isnt 1
            $textarea.disable()
            $textarea.val('')
        else
            $textarea.enable()
            $textarea.val(features.item(0).get('text'))

    if selectionChangeListener and activeMapcontrol
        activeMapcontrol.webgis.interactions.select.getFeatures().un 'change:length', selectionChangeListener

    selectionChangeListener = onSelectionChange

    activeMapcontrol = $view.find('.mapcontrol').first().mapcontrol()[0]
    activeMapcontrol.webgis.interactions.select.getFeatures().on 'change:length', selectionChangeListener

    onTextChange = (event) ->
        features = activeMapcontrol.webgis.interactions.select.getFeatures()
        features.item(0).set('text', event.target.value)
        calculateMeasurements features.item(0), activeMapcontrol

    $textarea.off 'input'

    $textarea.on 'input', onTextChange


enableViewCycling = ($ctx) ->
    transition = false
    current = 0
    $container = $ctx.find('.map-views')
    $views = $container.children()

    $button = $ctx.find('#cycle-views')
    $title = $ctx.find('#current-view-title')

    updateButtonText = ->
        $current = $($views[current])
        $title.text($current.data('title'))
        $next = $($views[(current + 1) % $views.length])
        $button.text($next.data('title'))

    updateButtonText()

    $button.on 'click', ->
        return if transition

        transition = true

        $container.removeClass 'in'
        $container.one 'transitionend', ->
            $previous = $($views[current])
            current = (current + 1) % $views.length
            $views.hide()
            $current = $($views[current])
            $current.show()

            updateButtonText()
            findMapControls($current).forEach((x) -> x.reset())

            setTimeout (-> copySelection($previous, $current)), 1

            setupFeatureTextEditing $current

            $container.addClass 'in'

            $container.one 'transitionend', ->
                transition = false

saveAnnotations = ->
    $saveButton = $('#save')
    map = getByName('combined-view')

    states = map.webgis.getModifiedGeometriesWKT()

    data =
        imageId: $('#ImageId').val()
        states: states
        entityId: $('#EntityId').val()

    url = $saveButton.data 'url'

    promise = antiForgeryPost url, data
    .then makeRefreshHandler()
    .then makeSuccessHandler $saveButton
    .fail makeErrorHandler $saveButton

    waitscreen.waitFor promise


setup = ($ctx, promise) ->
    promise.done -> getByName('combined-view').reset()
    $comparisonImages = $ctx.find('#comparison-images')
    $views = $ctx.find('.map-views')
    $saveButton = $ctx.find('#save')
    currentView = 0

    syncMapViews(findMaps($views))
    syncVectorLayers($views)
    enableViewCycling($ctx)

    $ctx.find('#annotation').disable()
    setupFeatureTextEditing($ctx.find('#comparison-combined'))
    $saveButton.on 'click', -> saveAnnotations()

    formatter = new WKT

    makeFeature = (vectorFeature) ->
        feature = formatter.readFeature vectorFeature.wkt
        feature.setId vectorFeature.uid
        feature.getGeometry().transform 'EPSG:4326', 'EPSG:900913'
        data = JSON.parse vectorFeature.data
        feature.setProperties
            uid: vectorFeature.uid
            text: data.text
        return feature

    combinedViewMapcontrol = $views.find('#combined-view').mapcontrol()[0]
    sbsViewMapcontrol = $views.find('#sbs-view-right').mapcontrol()[0]
    sbsViewLeftMapcontrol = $views.find('#sbs-view-left').mapcontrol()[0]


    $comparisonImages.on 'change', ->
        combinedViewMapcontrol.layerManager.groups.data.getLayers().clear()
        sbsViewMapcontrol.layerManager.groups.data.getLayers().clear()

        combinedLayers = combinedViewMapcontrol.layerManager.layers.vector()
        sbsLayers = sbsViewMapcontrol.layerManager.layers.vector()
        entityId = $('#EntityId').val()
        annotations = ['pointAnnotations', 'lineAnnotations', 'polygonAnnotations']

        if $comparisonImages.val()
            url = $comparisonImages.data 'url'

            get url, {comparisonImageId:$comparisonImages.val()}
            .done (result) ->
                for layers, i in [combinedLayers, sbsLayers]
                    for x, i in layers
                        if x.get('uid') is entityId
                            continue

                        source = x.getSource()
                        source.clear()

                        features = result[x.get('geometryType').toLowerCase()].map(makeFeature)
                        source.addFeatures features

                        for feature in features
                            calculateMeasurements feature, combinedViewMapcontrol

        else
            for x, i in layers
                if x.get('uid') is entityId
                    continue

                source = x.getSource()
                source.clear()

        getlayerStringUrl = $comparisonImages.data 'layer-string-url'

        replaceImageLayers = (mapcontrol) -> (layerString) ->
            for layer in (x for x in layerString.split ';' when x)
                    args = [
                        layer
                        mapcontrol.options.servers.primary
                        mapcontrol.options.servers.primary.url isnt local.url
                    ]
                    layer = mapcontrol._defineWmsLayer args...
                    mapcontrol.layerManager.groups.data.getLayers().push layer

        get getlayerStringUrl, {currentImageId: $('#ImageId').val(), comparisonImageId: $comparisonImages.val()}
        .done replaceImageLayers(combinedViewMapcontrol)

        get getlayerStringUrl, {comparisonImageId: $comparisonImages.val()}
        .done replaceImageLayers(sbsViewMapcontrol)


register 'App.UAV.ImageManagement.Analysis.setup', setup
