{register} = require 'src/js/infrastructure/setup-registry'

setup = ($ctx) ->
    format = Globalize.numberFormatter
        useGrouping: false

    parse = Globalize.numberParser()

    applyOutputValues = ->
        $slider = $(this)
        # atm we need to parse and format the number, it won't work otherwise (Globalize.numberParser is not needed)
        $('.slider-output[for=' + $slider.attr('id') + ']').val format parseFloat $slider.val()

    updateSlider = (event) ->
        input = event.currentTarget
        slider = document.getElementById(input.getAttribute('for'))
        slider.value = parse input.value

    updateOutput = (event) ->
        input = event.currentTarget
        slider = document.getElementById(input.getAttribute('for'))
        input.value = format parseFloat slider.value

    # if the stepsize doesn't match the digits, the slider would truncate the numbers, but that would not update the output properly -> applyOutputValues
    $ctx.find('input[type=range]')
    .each applyOutputValues
    .on 'input', applyOutputValues

    $ctx.find('.slider-output')
    .on 'input', updateSlider
    .on 'focusout', updateOutput

register 'App.UAV.ZoneMaps.Slider.setup', setup
