
range = (start, length) -> [start..start + length - 1]

lerp = (t, min, max) -> min + t * (max - min)

lerpArrayElement = (t, first, second, index) ->
    lerp(t, first[index], second[index])

lerpArray = (t, first, second) ->
    range(0, first.length).map((i) -> lerpArrayElement(t, first, second, i))

# calculate the index of the bin the value belongs to
# bins array should include both endpoints of the value range, having N+1 elements for N bins
# quantized value will be an int in range [0, bins.length)
quantize = (value, bins) ->
    for v, i in bins
        continue if i is 0
        if v >= value
            return i - 1
    throw new Error('value outside of given range')

ndvi = (pixel) -> pixel

zoneMapColorPalette = [
    [0, 0, 255, 255]
    [0, 255, 255, 255],
    [0, 255, 0, 255],
    [255, 255, 0, 255],
    [255, 0, 0, 255],
]

zonemapColor = (zoneIndex, zoneCount) ->
    gamma = if zoneCount > 1 then zoneIndex / (zoneCount - 1) else 0.5
    gammaStops = [0, 0.25, 0.5, 0.75, 1]
    colorIndex = quantize(gamma, [0, 0.25, 0.5, 0.75, 1])
    t = (gamma - gammaStops[colorIndex]) * 4
    color = lerpArray(t, zoneMapColorPalette[colorIndex], zoneMapColorPalette[colorIndex + 1]).map(Math.round)
    return color

createZonemapColormappingOperation = (bins, histogram) ->
    if not bins.length
        operation = (pixels, data) ->
            [pixel, mask] = pixels
            if mask[0]
                return pixel
            else
                return [0, 0, 0, 0]
        return {
            data: {
                bins: [-1, 1]
                zoneColors: [[0, 0, 0, 255]]
                highlight: [-1, -1]
                histogram
            }
            lib: {}
            operation
        }

    zoneColors = range(0, bins.length - 1).map((i) -> zonemapColor(i, bins.length - 1))
    operation = (pixels, data) ->
        [pixel, mask] = pixels
        return [0, 0, 0, 0] unless mask[0] and pixel[3]

        normalized = pixel[0] / 255
        ndvi = 2 * normalized - 1

        data.histogram[Math.floor(normalized * data.histogram.length)] += 1

        return [255, 255, 255, 255] if normalized >= data.highlight[0] and normalized <= data.highlight[1]

        # embedded for worker + minification compatibility
        embeddedQuantize = (value, bins) ->
            for v, i in bins
                continue if i is 0
                if v >= value
                    return i - 1
            throw new Error('value outside of given range')

        return data.zoneColors[embeddedQuantize(ndvi, data.bins)]

    data = {bins, zoneColors, highlight: [-1, -1], histogram}
    lib = {}
    return {operation, data, lib}

module.exports = {
    range,
    lerp,
    lerpArrayElement,
    lerpArray,
    quantize,
    ndvi,
    createZonemapColormappingOperation
}
