{waitscreen, notifications} = require 'src/js/infrastructure'
{register} = require 'src/js/infrastructure/setup-registry'
{antiForgeryUploadFiles} = require 'src/coffee/infrastructure/ajax'

{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'
{makeReplaceHandler} = require 'src/coffee/helpers/async/fragment-loading'

setup = ($ctx) ->
    $modal = $ctx.find('#upload-image.modal')
    $uploadButton = $modal.find('#upload')
    url = $uploadButton.data 'url'
    $form = $ctx.find('#upload-form')
    $cancelUploadBtn = $('#waitscreen-cancel')

    $uploadButton.on 'click', ->
        $cancelUploadBtn.off 'click'
        $cancelUploadBtn.removeClass('hidden')
        uploadingMessage = $uploadButton.data('uploading-message')
        processingMessage = $uploadButton.data('processing-message')
        estimate = $uploadButton.data('estimate-message')

        uploadProgress = (event) ->

            if event.lengthComputable
                progress = event.loaded / event.total
                waitscreen.setProgress(progress, uploadingMessage)
                if progress is 1
                    setTimeout (-> waitscreen.setProgress(undefined, processingMessage, estimate)), 1000

        if $form.valid()
            data =
                recorded: $modal.find('#recorded-date-upload-modal').getISODateTime()
                name: $modal.find('#name-upload-modal').val()
                description: $modal.find('#description-upload-modal').val()
                fkCamera: $modal.find('#cameras-upload-modal').val()
                fkEntity: $('#EntityId').val()
                file: $('#filepicker').get(0).files[0]

            upload = antiForgeryUploadFiles url, data, null, null, uploadProgress

            upload.then makeReplaceHandler $('#content')
            upload.then makeSuccessHandler $uploadButton

            showUploadError = makeErrorHandler($uploadButton)
            upload.fail (jqXHR, textStatus, errorThrown) ->
                if jqXHR.responseJSON?.message
                    notifications.error(jqXHR.responseJSON.message)
                else
                    showUploadError()

            upload.always ->
                $modal.modal('hide')
                waitscreen.hide()
                $cancelUploadBtn.addClass('hidden')

            $cancelUploadBtn.on 'click', (event) ->
                upload.abort($uploadButton.data('upload-cancelled-message'))

register 'App.UAV.ImageManagement.UploadImageModal.setup', setup
