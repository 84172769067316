{waitscreen} = require 'src/js/infrastructure'
{register} = require 'src/js/infrastructure/setup-registry'
{antiForgeryPost} = require 'src/coffee/infrastructure/ajax'

{makeSuccessHandler, makeErrorHandler} = require 'src/coffee/helpers/async/notifications'

setup = ($ctx) ->
    $modal = $ctx.find('#edit-image-modal')
    $saveButton = $modal.find('#save-details')
    url = $saveButton.data 'url'
    $form = $ctx.find('#details-form')
    $saveButton.on 'click', ->
        if $form.valid()
            data =
                uid: $('#images').val()
                name: $modal.find('#name-edit-modal').val()
                recorded: $modal.find('#recorded-date-edit-modal').data('DateTimePicker').date()
                description: $modal.find('#description-edit-modal').val()

            promise = antiForgeryPost url, data
            .then ->
                selectize = $('#images').selectize()[0].selectize
                option = selectize.options[data.uid]
                option.text = data.name
                option.label = data.recorded.format("L HH:mm:ss")
                selectize.clearCache()
                selectize.updateOption(option.value, option)
                $('#description').text data.description
                $('#description').val data.description
                $('#recorded').data('DateTimePicker').date data.recorded
            .then makeSuccessHandler $saveButton
            .fail makeErrorHandler $saveButton
            .always -> $modal.modal('hide')

            waitscreen.waitFor promise


register 'App.UAV.ImageManagement.EditDetails.setup', setup
