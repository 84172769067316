{waitscreen} = require 'src/js/infrastructure'
{register} = require 'src/js/infrastructure/setup-registry'

{get} = require 'src/coffee/infrastructure/ajax'
{makeReplaceHandler} = require 'src/coffee/helpers/async/fragment-loading'

{getModal} = require 'src/coffee/controls/modals'

setup = ($ctx, promise) ->


    $ctx.find('#images').on 'change', ->
        updateDownloadButton()
        reloadImageDetails()

    promise.then updateDownloadButton
    promise.then reloadImageDetails

updateDownloadButton = ->
    $btn = $('#download-image')
    imageId = $('#images').val()

    if imageId
        href = $btn.data('url-template')
        href = href.replace('{0}', imageId)
        href = href.replace(encodeURIComponent('{0}'), imageId)
        $btn.attr 'href', href
        $btn.enable()
    else
        $btn.attr 'href', ''
        $btn.disable()

reloadImageDetails = ->
    imageId = $('#images').val()
    $container = $('#image-details')

    if imageId
        url = $container.data 'url'
        data =
            imageId: imageId
            entityId: $('#EntityId').val()
        promise = get url, data
        .then makeReplaceHandler $container

        waitscreen.waitFor promise
    else
        $container.empty()

register 'App.UAV.ImageManagement.setup', setup
