# CoffeeScript

{Plugin} = require './base'

class LayerSwitcherPlugin extends Plugin
    init: (options) ->
        @options =
            miscLayerHeadline: options.headlines.miscLayer
            baseLayerHeadline: options.headlines.baseLayer
            wmsLayerHeadline: options.headlines.wmsLayer

        @menu = @mapcontrol.slideoutmenu

        $button = $ '<button>'
        .addClass 'layer-switcher-button'
        .attr 'title', options.tooltips.switchLayer
        .append '<i class="icon icon-ix-layer">'

        @$content = $ '<div>'
        .addClass 'layer-switcher'
        .on 'show', @updateContent

        @menu.addEntry $button, @$content

    createLineLabel: (layer) ->
        $line = $ '<p class="layer-line">'

        $div = $ '<div>'
        .appendTo $line

        $label = $ '<label class="layer-label">'
        .text layer.get 'name'
        .appendTo $div

        return $div

    createLayerToggle: (layer, relatedLayers, toggleGroup) ->
        $div = @createLineLabel layer

        $div.addClass 'radio'

        $radio = $ '<input type="radio" name="'+toggleGroup+'">'
        .prop 'checked', layer.getVisible()
        .prependTo $div.children().first()
        .on 'change', ->
            for relatedLayer in relatedLayers
                relatedLayer.setVisible false
            layer.setVisible $(this).prop 'checked'

        return $div.parent()

    createLayerLine: (layer, zoomOverride) ->
        $div = @createLineLabel layer

        $div.addClass 'checkbox'

        updateMaxZoom = =>
            if zoomOverride
                view = @mapcontrol.map.getView()
                defaultMax = @mapcontrol.options.maxZoom
                newMaxZoom = if layer.getVisible() then defaultMax + 4 else defaultMax
                newZoom = Math.min(view.getZoom(), newMaxZoom)
                view.setZoom(newZoom)
                view.setMaxZoom(newMaxZoom)

        $checkbox = $ '<input type="checkbox">'
        .prop 'checked', layer.getVisible()
        .prependTo $div.children().first()
        .on 'change', ->
            layer.setVisible $(this).prop 'checked'
            updateMaxZoom()

        return $div.parent()

    updateContent: =>
        groups = @mapcontrol.layerManager.groups
        @$content.empty()

        selectLayers = (layerGroups...) ->
            layers = []
            for group in layerGroups
                # undefined fix, coffee slices the arguments array and puts in undefinied objects into the layerGroups
                if group
                    for layer in group.getLayers().getArray()
                        layers.push layer
            return layers

        makeLayerGroup = (name, collapsed, count) =>
            $groupContainer = $ '<div class="layer-group">'
            $groupHeader = $ "<h4 class='layer-group-header'> #{name}</h4>"
            $groupHeaderCount = $ "<small> (#{count})</small>"
            $groupCaret = $ "<i class='fa fa-caret-#{if collapsed then 'down' else 'up'}'>"
            $groupContent = $ "<div class='layer-group-layers collapse #{if collapsed then '' else 'in'}'></div>"

            $groupContainer.appendTo @$content
            $groupHeader.appendTo $groupContainer
            $groupHeaderCount.appendTo $groupHeader
            $groupContent.appendTo $groupContainer
            $groupCaret.prependTo $groupHeader

            $groupHeaderCount.hide() unless collapsed

            $groupContent.collapse({toggle: false})
            $groupHeader.on 'click', =>
                collapsed = not collapsed
                $groupContent.collapse('toggle')
                $groupCaret.toggleClass 'fa-caret-down'
                $groupCaret.toggleClass 'fa-caret-up'
                if collapsed
                    $groupHeaderCount.show()
                else
                    $groupHeaderCount.hide()

                resizeEvent = if collapsed then 'hidden.bs.collapse' else 'shown.bs.collapse'
                $groupContent.on(resizeEvent, => @$content.trigger('resize'))

            return $groupContent

        customLayers = selectLayers groups.webgis, groups.vector, groups.utilities
        $customLayerGroup = makeLayerGroup(@options.miscLayerHeadline, false, customLayers.length)

        for layer in customLayers
            $customLayerGroup.append @createLayerLine layer

        wmsLayers = selectLayers groups.data, groups.external, groups.climate, groups.satellite
        if wmsLayers.length
            $wmsLayerGroup = makeLayerGroup(@options.wmsLayerHeadline, true, wmsLayers.length)

            for layer in wmsLayers
                $wmsLayerGroup.append @createLayerLine layer

        droneLayers = selectLayers groups.drone
        satLayers = selectLayers groups.base

        $baseLayerGroup = makeLayerGroup(@options.baseLayerHeadline, true, droneLayers.length + satLayers.length)

        for layer in droneLayers
            $baseLayerGroup.append @createLayerLine layer, true
        for layer in satLayers
            $baseLayerGroup.append @createLayerToggle layer, (selectLayers groups.base), 'baseLayers'

module.exports = {
    LayerSwitcherPlugin
}

module.exports.__esModule = true
module.exports.default = LayerSwitcherPlugin
